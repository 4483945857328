import gql from 'graphql-tag';
import {
  CARGOTYPE_DELETE_SUCCESS,
  CARGOTYPE_DELETE_START,
  CARGOTYPE_DELETE_ERROR
} from '../../constants/index';
import { toastr } from 'react-redux-toastr';
import { setLoaderComplete, setLoaderStart } from '../loader/loader';

export default function deleteCargoType(id, currentPage) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: CARGOTYPE_DELETE_START
    });

    dispatch(setLoaderStart('DeleteCargoType'));
    try {
      let errorMessage;

      let query = gql`
        query getAllCargoType($currentPage: Int, $searchList: String){
          getAllCargoType(currentPage: $currentPage, searchList: $searchList){
            count
            cargoTypeData{
                id
                name
                description
                isActive
                createdAt
                updatedAt
            }
          }
        }
      `;

      let mutation = gql`
        mutation deleteCargoType($id: Int) {
          deleteCargoType(id: $id) {
            status
            errorMessage
          }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        },
        refetchQueries: [{ query, variables: { currentPage, searchList: '' } }]
      });

      dispatch(setLoaderComplete('DeleteCargoType'));

      if (data && data.deleteCargoType && data.deleteCargoType.status === 200) {
        dispatch({
          type: CARGOTYPE_DELETE_SUCCESS
        });
        toastr.success('Success', `The CargoType has been deleted.`);
      } else if (data && data.deleteCargoType && data.deleteCargoType.status === 404) {
        dispatch({
          type: CARGOTYPE_DELETE_ERROR
        });
      } else {
        dispatch({
          type: CARGOTYPE_DELETE_ERROR
        });

        errorMessage = (data && data.deleteCargoType && data.deleteCargoType.errorMessage) || "Oops! Something went wrong. Please try again.";
        toastr.error('Error!', errorMessage);
      }
    } catch (err) {
      toastr.error('Error!', err);
      dispatch({
        type: CARGOTYPE_DELETE_ERROR
      });
    }
  };
}
