import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';

import {
  SET_CASH_PAYOUT_START,
  SET_CASH_PAYOUT_SUCCESS,
  SET_CASH_PAYOUT_ERROR,
} from '../../../constants';

export function updateCashPayout(id, payoutStatus, refetch, changeLoaderState, searchList, payoutFilterStatus, currentPage) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SET_CASH_PAYOUT_START,
    });

    try {
      let getPayoutList = gql`
      query getPayoutList($currentPage: Int, $searchList: String){
        getPayoutList(currentPage: $currentPage, searchList:$searchList){
          count
        bookingData{
          id
          tripStatus
          driverDetails{
            firstName
            userData{
              email
            }
          }
          totalFare
          isPayoutPaid
          isBanStatus
          paymentType
        }
      }
    }
      `

      let mutation = gql`
        mutation updateCashPayout ($id: Int, $payoutStatus: Boolean, $currentPage: Int, $searchList: String, $payoutFilterStatus: String){
          updateCashPayout(id: $id, payoutStatus: $payoutStatus, currentPage: $currentPage, searchList: $searchList, payoutFilterStatus: $payoutFilterStatus){
              status
              errorMessage
              havingData
            }
        }`;

      const { data } = await client.mutate({
        mutation,
        variables: { id, payoutStatus, searchList, payoutFilterStatus, currentPage },
        // refetchQueries: [{ query: getPayoutList, variables: { currentPage, searchList: '' } }]
      });

      if (data.updateCashPayout.status == 200) {
        if (refetch) await refetch(data.updateCashPayout.havingData ? null : 1);
        if (changeLoaderState) changeLoaderState('successUpdateStatus', id);
        dispatch({
          type: SET_CASH_PAYOUT_SUCCESS,
        });

        toastr.success("Success!", "Payout status has been updated for the trip");

      } else {

        dispatch({
          type: SET_CASH_PAYOUT_ERROR
        });

        toastr.error("Oops!", data.updateCashPayout.errorMessage || "Failed to update status for Payout");
      }
      if (changeLoaderState) changeLoaderState('removeUpdateStatus', id);
    } catch (error) {

      dispatch({
        type: SET_CASH_PAYOUT_ERROR
      });

      toastr.error("Oops!", "Failed to update status for Payout");

      return false;
    }
    return true;
  };
}
