import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import ReactGoogleMapLoader from "react-google-maps-loader";
import {
	GoogleMap,
	Marker,
	HeatmapLayer,
} from "@react-google-maps/api";

import { googleMapAPI } from '../../../../config';

import users from '../../../../../public/Icons/MapMarker/users.png';
import availableDrivers from '../../../../../public/Icons/MapMarker/availableDrivers.png';
import unActivatedDrivers from '../../../../../public/Icons/MapMarker/unActivatedDrivers.png';
import unAvailableDrivers from '../../../../../public/Icons/MapMarker/unAvailableDrivers.png';

import { api, categoryUploadDir } from '../../../../config'
import getActiveCategories from '../getActiveCategories.graphql'

import { graphql } from 'react-apollo';

class ShowMap extends Component {
	static defaultProps = {
		zoom: 4,
		height: '400px',
		width: '100%',
		center: { lat: 55.3781, lng: 3.4360 },
		bounds: []
	};


	constructor(props) {
		super(props);
		this.onLoad = this.onLoad.bind(this);
	}

	onLoad(map) {
		const { mapMarkerPoints, heatMapData, showSection, type } = this.props;
		let bounds = new google.maps.LatLngBounds();

		if (showSection === 0 && map && mapMarkerPoints && mapMarkerPoints.length > 0) {
			mapMarkerPoints.map((i) => {
				let positionData;
				if (type == 3) {
					positionData = new google.maps.LatLng(parseFloat(i.lat), parseFloat(i.lng))
				} else {
					if (i.userType == 2 && i.isActive == 1 && i.activeStatus == 'active' && i.userStatus == 'active') {
						positionData = new google.maps.LatLng(parseFloat(i.lat), parseFloat(i.lng))
					} else {
						positionData = new google.maps.LatLng(parseFloat(i.profile.lat), parseFloat(i.profile.lng))
					}
				}
				bounds.extend(positionData);
			});
			map.fitBounds(bounds);
		}
		else if (showSection === 1 && map && heatMapData && heatMapData.length > 0) {
			heatMapData.map((i) => {
				bounds.extend(i);
			});
			map.fitBounds(bounds);
		}
	}

	render() {
		const {  mapMarkerPoints, type, zoom, height, width, center, heatMapData, showSection } = this.props;


		const getIcon = (vehicleType) => {
			const { getActiveCategories: { getActiveCategories } } = this.props;
			if (!getActiveCategories || !getActiveCategories.result) {
				console.error('Category data is not available');
				return null; // Retorna null si no hay categorías disponibles
			}
		
			let iconUrl;
			for (let category of getActiveCategories.result) {
				if (category.id === vehicleType) {
					iconUrl = `${api.apiEndpoint}${categoryUploadDir}${category.categoryImage}`;
					break; // Detiene el ciclo cuando encuentra el tipo de vehículo correspondiente
				}
			}
		
			if (!iconUrl) return null; // Retorna null si no se encontró un ícono apropiado
		
			// Crea y retorna un objeto icono con URL y tamaño
			return {
				url: iconUrl,
				scaledSize: new google.maps.Size(50, 30) // Escala el tamaño del ícono a 10x10 píxeles
			};
		};
		
		
		

		let mapStyles = [
			{
				featureType: "poi",
				elementType: "geometry",
				stylers: [
					{
						color: "#eeeeee",
					},
				],
			},
			{
				featureType: "poi",
				elementType: "labels.text",
				stylers: [
					{
						visibility: "off",
					},
				],
			},
			{
				featureType: "water",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#9e9e9e",
					},
				],
			},
		];

		let options = {
			styles: mapStyles,
			disableDefaultUI: true,
			minZoom: 2,
			maxZoom: 22,
			zoomControl: true,
		}

		if (showSection === 1) {
			options['mapTypeId'] = "satellite"
		} else {
			options['mapTypeId'] = "roadmap"
		}

		return (
			<ReactGoogleMapLoader
				params={{
					key: googleMapAPI,
					libraries: "geometry,drawing,places,visualization"
				}}
				render={googleMaps =>
					googleMaps && (
						<div>
							<GoogleMap
								ref={(map) => this.map = map}
								zoom={zoom}
								center={center}
								mapContainerStyle={{ height, width }}
								options={{
									...options,
									zoomControlOptions: {
										position: google.maps.ControlPosition.LEFT_TOP
									}
								}}
								onLoad={(map) => this.onLoad(map)}
							>
								{
									showSection === 0 && mapMarkerPoints && mapMarkerPoints.length > 0 && mapMarkerPoints.map((position, index) => {
										let positionData, icon = null;
										if (type === '1') {
											if (position.userType === 1) {
												icon = users;
											} else if (position.userType === 2) {
												if (position.userStatus === 'active' && position.isActive === 1 && position.activeStatus == 'active') {
													icon = availableDrivers;
												} else if (position.userStatus === 'active' && position.isActive === 1 && position.activeStatus == 'inactive') {
													icon = unAvailableDrivers;
												} else if (position.userStatus === 'pending' || position.userStatus === 'inactive') {
													icon = unActivatedDrivers;
												}
											}
										} else if (type === '2') {
											icon = users;
										} else if (type === '3') {
											icon = availableDrivers;
										} else if (type === '4') {
											icon = unAvailableDrivers;
										} else if (type === '5') {
											icon = unActivatedDrivers;
										}

										if (type == 3) {
											positionData = new google.maps.LatLng(parseFloat(position.lat), parseFloat(position.lng))
										} else {
											if (position.userType == 2 && position.isActive == 1 && position.activeStatus == 'active' && position.userStatus == 'active') {
												positionData = new google.maps.LatLng(parseFloat(position.lat), parseFloat(position.lng))
											} else {
												positionData = new google.maps.LatLng(parseFloat(position.profile.lat), parseFloat(position.profile.lng))
											}
										}

										console.log(position.vehicles[0].vehicleType);

										return (<Marker
											key={index}
											position={positionData}
                                   			icon={getIcon(position.vehicles[0].vehicleType)}
										/>)
									})
								}

								{
									showSection === 1 && heatMapData && heatMapData.length > 0 && <HeatmapLayer
										data={heatMapData}
									/>
								}

							</GoogleMap>
						</div>
					)
				}
			/>
		)
	}
}

const mapState = state => ({});

const mapDispatch = {
	change
};

export default connect(mapState, mapDispatch)(
	graphql(getActiveCategories, {
		name: 'getActiveCategories',
		options: {
			fetchPolicy: 'network-only',
			ssr: true
		}
	})(ShowMap)
  );
