import gql from 'graphql-tag';
import histroy from '../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';

export function addCargoType(name, description, isActive) {

  return async (dispatch, getState, { client }) => {
    const mutation = gql`
        mutation addCargoType(
          $name: String,
          $description: String,
          $isActive: Boolean,
         
        ) {
          addCargoType(
          name: $name,
          description: $description,
          isActive: $isActive,
          
        ){
          status
          errorMessage
        }
      }
    `;

    dispatch(setLoaderStart('addCargoType'));

    if(isActive === undefined){
      isActive  = true;
    }


    const { data } = await client.mutate({
      mutation,
      variables: {
        name,
        description,
        isActive: String(isActive) === 'true' ? 1 : 0,
      }
    });
    
    dispatch(setLoaderComplete('addCargoType'));

    if (data && data.addCargoType && data.addCargoType.status === 200) {
      toastr.success('Success!', 'The Cargo Type has been added successfully.');
      histroy.push('/siteadmin/cargoType');
    } else if (data && data.addCargoType && data.addCargoType.status !== 200){
      toastr.error('Error!', data.addCargoType.errorMessage);
    } else {
      toastr.error('Error!', "Something went wrong. Please try again.");
    }
  }
}