import { sendNotifications } from '../../../helpers/push-notification/sendNotifications';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { reset } from 'redux-form';

async function submit(values, dispatch, selectedUsers) {
    // Extrae el contenido del mensaje y el título de los valores del formulario
    let content = {
        title: ' Trineo!',
        message: values.message,
    };

    dispatch(setLoaderStart('sendNotification'));

   
    await sendNotifications(content, selectedUsers); 

    // Resetea el formulario después de enviar las notificaciones
    dispatch(reset('ManageNotificationsForm'));
    dispatch(setLoaderComplete('sendNotification'));
}

export default submit;
