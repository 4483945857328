import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, initialize ,change} from 'redux-form';
import {
    Row,
    Table,
    FormGroup,
    Col,
    FormControl,
    Container
} from 'react-bootstrap';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages'
import s from './ManageNotificationsForm.css'
import bt from '../../../components/commonStyle.css';
import submit from './submit'
import validate from './validate'
import Loader from '../../Common/Loader';
import getAllRiders from './getAllRiders.graphql';
import getAllUsers from './getAllUsers.graphql'; // Importa la consulta GetAllUsers
import getActiveCategories from './getActiveCategories.graphql'
import { compose } from 'lodash/fp';
import { graphql } from 'react-apollo';
import CustomPagination from '../../CustomPagination';

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { formValueSelector } from 'redux-form';
import i18n from '@emoji-mart/data/i18n/es.json';



export class ManageNotificationsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            searchList: '',
            selectedUsers: [],
            typeAcount: '',
            gender: '',
            divice: '',
            acountStatus: '',
            isActive: '',
            vehicleStatus: '',
            vehicleType: '',
        };
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.formGroup}>
                    <div>
                        <label className={bt.labelText} >{label}</label>
                    </div>
                    <div className={'textAreaRTL'}>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="3"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    handleChange = async (event) => {
        const { name, value } = event.target;

        // Actualiza el estado con el nuevo valor del filtro
        await this.setState({ [name]: value });

        this.fetchRiders2();
    }

    fetchRiders2 = () => {
        const { typeAcount, gender, divice, acountStatus, vehicleStatus, vehicleType, isActive } = this.state;
        const { riderDetails: { refetch } } = this.props;


        console.log(divice)

        // Construye las variables basadas en el estado actual de los filtros
        const variables = {
            currentPage: 1,
            typeAcount,
            gender,
            divice,
            acountStatus,
            isActive,
            vehicleStatus,
            vehicleType,
            // Aquí puedes añadir más variables si son necesarias
        };

        // Refetch los riders con los nuevos filtros
        refetch(variables)
            .then(({ data }) => {
                console.log("Datos de usuarios filtrados:", data);
            })
            .catch(error => {
                console.error("Error al filtrar usuarios:", error);
            });
    }

    fetchRiders = () => {
        const { searchList, currentPage } = this.state;
        const { riderDetails: { refetch } } = this.props;
        refetch({ searchList, currentPage });
    };


    handleCheckboxChange = (userId) => {
        this.setState(prevState => {
            const { selectedUsers } = prevState;
            if (selectedUsers.includes(userId)) {
                return { selectedUsers: selectedUsers.filter(id => id !== userId) };
            } else {
                return { selectedUsers: [...selectedUsers, userId] };
            }
        });
    };

    // Cambio en la lógica para identificar si todos los usuarios en la página actual están seleccionados
    areAllSelected = () => {
        const { riderDetails: { getAllRiders } } = this.props;
        const { selectedUsers, currentPage } = this.state;
        const currentPageUsers = getAllRiders.userData.map(user => user.id);
        return currentPageUsers.every(userId => selectedUsers.includes(userId));
    };

    areAllSelected2 = () => {
        const { riderDetails: { getAllRiders } } = this.props;
        const { selectedUsers, currentPage } = this.state;
        const currentPageUsers = getAllRiders.allUser.map(user => user.id);
        return currentPageUsers.every(userId => selectedUsers.includes(userId));
    };

    // Ajusta para seleccionar o deseleccionar solo en la página actual
    toggleSelectAll = () => {
        const { riderDetails: { getAllRiders } } = this.props;
        const { selectedUsers, currentPage } = this.state;
        const currentPageUserIds = getAllRiders.userData.map(user => user.id);

        if (this.areAllSelected()) {
            this.setState({
                selectedUsers: selectedUsers.filter(id => !currentPageUserIds.includes(id)),
            });
        } else {
            this.setState({
                selectedUsers: [...new Set([...selectedUsers, ...currentPageUserIds])],
            });
        }

    };

    toggleSelectAll2 = () => {
        const { riderDetails: { getAllRiders } } = this.props;
        const { selectedUsers, currentPage } = this.state;
        const currentPageUserIds = getAllRiders.allUser.map(user => user.id);

        if (this.areAllSelected()) {
            this.setState({
                selectedUsers: selectedUsers.filter(id => !currentPageUserIds.includes(id)),
            });
        } else {
            this.setState({
                selectedUsers: [...new Set([...selectedUsers, ...currentPageUserIds])],
            });
        }

    };

    handleSearchChange = (e) => {
        this.setState({ searchList: e.target.value, currentPage: 1 }, this.fetchRiders);
    };

    paginationData = (currentPage) => {
        this.setState({ currentPage }, this.fetchRiders);
    };

    handleEmojiSelect = (emoji) => {
        const currentText = this.props.message || "";
        const newText = currentText + emoji.native;
        // Utiliza directamente la acción 'change' de Redux Form para actualizar el campo 'message'
        this.props.dispatch(change('ManageNotificationsForm', 'message', newText));
    };

    vehicleTypeOptions = () => {
        const { handleSubmit, submitting, getActiveCategories: { getActiveCategories }, riderDetails: { loading, getAllRiders } } = this.props;
        let options = {};
        if (getActiveCategories && getActiveCategories.result) {
            getActiveCategories.result.forEach(category => {
                options[category.id] = category.categoryName;
            });
        }
        return options;
    };

    renderAppliedFilters = () => {
        const { typeAcount, gender, divice, acountStatus, isActive, vehicleStatus, vehicleType } = this.state;
        const filters = [
            { label: 'Tipo de Cuenta', value: typeAcount, options: { '': 'Todos', '2': 'Conductor', '1': 'Pasajero' } },
            { label: 'Género', value: gender, options: { '': 'Todos', 'male': 'Masculino', 'female': 'Femenino', 'other': 'Otro' } },
            { label: 'Dispositivo', value: divice, options: { '': 'Todos', 'android': 'Android', 'ios': 'iOS' } },
            { label: 'Estado (isActive)', value: isActive, options: { '': 'Todos', '1': 'Activo', '0': 'Inactivo' } },
            { label: 'Estado de Cuenta', value: acountStatus, options: { '': 'Todos', 'active': 'Activo', 'pending': 'Pendiente', 'inactive': 'Inactivo' } },
            { label: 'Estado del Vehículo', value: vehicleStatus, options: { '': 'Todos', 'pending': 'Pendiente', 'active': 'Activo', 'inactive': 'Inactivo' } },
            { label: 'Tipo de Vehículo', value: vehicleType, options: this.vehicleTypeOptions() },
        ];
    
        const appliedFilters = filters.filter(filter => filter.value !== '');
    
        return (
            <div>
                <h4>Filtros Aplicados:</h4>
                {appliedFilters.length > 0 ? (
                    <ul>
                        {appliedFilters.map((filter, index) => (
                            <li key={index}>{filter.label}: {filter.options[filter.value]}</li>
                        ))}
                    </ul>
                ) : <p>Sin filtros</p>}
            </div>
        );
    };
    
    
    

    renderSelectedUsersList = () => {
        const { selectedUsers } = this.state;
        const { riderDetails: { getAllRiders } } = this.props;
        
        // Filtrar los usuarios seleccionados basados en selectedUsers IDs
        const selectedUsersDetails = getAllRiders.allUser.filter(user => selectedUsers.includes(user.id));
        
        if (selectedUsersDetails.length > 0) {
          return (
            <div>
                {this.renderAppliedFilters()} 
              <h4>Usuarios seleccionados:</h4>
              <ul>
                {selectedUsersDetails.map((user, index) => (
                  <li key={index}>{user.profile.firstName} {user.profile.lastName} - {user.email}</li>
                ))}
              </ul>
            </div>
          );
        }
        
        return null;
      };

    
    handlePaste = (event) => {
        event.preventDefault();
    
        const text = event.clipboardData.getData('text');
        const emails = text.split(',');
    
        const { riderDetails: { getAllRiders } } = this.props;
        // Aquí, cambiamos el mapeo para almacenar correos electrónicos en lugar de IDs
        const existingUsersEmails = getAllRiders.allUser
            .filter(user => emails.includes(user.email))
            .map(user => user.email); // Cambio clave aquí: almacenamos el correo
    
        const rejectedEmails = emails.filter(email => !getAllRiders.allUser.some(user => user.email === email));
    
        if (existingUsersEmails.length > 0) {
            // Necesitas una manera de seleccionar usuarios por correo electrónico si estás almacenando correos en lugar de IDs.
            // Asegúrate de que esto se ajuste a cómo manejas la selección de usuarios en el estado.
            const existingUsersIds = getAllRiders.allUser
                .filter(user => existingUsersEmails.includes(user.email))
                .map(user => user.id);
            this.setState({ selectedUsers: [...new Set([...this.state.selectedUsers, ...existingUsersIds])] });
    
            // Muestra correos electrónicos en la alerta
            alert(`Los siguientes correos se encontraron: ${existingUsersEmails.join(', ')}`);
        }
    
        if (rejectedEmails.length > 0) {
            alert(`Los siguientes correos no se encontraron: ${rejectedEmails.join(', ')}`);
        }
    };
    
      

    render() {
        const { formatMessage } = this.props.intl
        const { handleSubmit, submitting, getActiveCategories: { getActiveCategories }, riderDetails: { loading, getAllRiders } } = this.props;
        const { currentPage, searchList, selectedUsers } = this.state;
        // Antes de renderizar el componente o como parte de su estado
        const vehicleTypeMap = {};
        if (getActiveCategories && getActiveCategories.result) {
        getActiveCategories.result.forEach(category => {
            vehicleTypeMap[category.id] = category.categoryName;
        });
        }
        const statusTranslations = {
            active: 'Activo',
            pending: 'Pendiente',
            inactive: 'Inactivo',
            male: 'Masculino',
            female:'Femenino',
            other:'Otros',
            android:'Android',
            ios:'IOS'
        };
        


        if (loading) return <Loader type="circle" />;

        console.log(selectedUsers)

        return (
            <div className={'customRatioButton'}>
                <div>
                    <Container fluid>

                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12}   >


                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} >
                                        <p>Configuración Notificación</p>


                                        <Row>
                                            <Col xs={12} sm={4} md={4} lg={4} >
                                                <FormGroup className={s.formGroup}  >
                                                    <label className={bt.labelText} >Tipo de Cuenta</label>
                                                    <Field onChange={this.handleChange} name="typeAcount" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                                                        <option value="">{formatMessage(messages.allonly)}</option>
                                                        <option value="2">{formatMessage(messages.driver)}</option>
                                                        <option value="1">{formatMessage(messages.rider)}</option>
                                                    </Field>
                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} sm={4} md={4} lg={4}>
                                                <FormGroup className={s.formGroup}>
                                                    <label className={bt.labelText} >{formatMessage(messages.gender)}</label>
                                                    <Field onChange={this.handleChange} name="gender" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                                                        <option value="">{formatMessage(messages.allonly)}</option>
                                                        <option value="male">{formatMessage(messages.male)}</option>
                                                        <option value="female">{formatMessage(messages.female)}</option>
                                                        <option value="other">{formatMessage(messages.otherGender)}</option>
                                                    </Field>
                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} sm={4} md={4} lg={4}>
                                                <FormGroup className={s.formGroup}>
                                                    <label className={bt.labelText} >Dispositivo</label>
                                                    <Field onChange={this.handleChange} name="divice" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                                                        <option value="">{formatMessage(messages.allonly)}</option>
                                                        <option value="android">Android</option>
                                                        <option value="ios">iOS</option>

                                                    </Field>
                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} sm={3} md={3} lg={3}>
                                                <FormGroup className={s.formGroup}>
                                                    <label className={bt.labelText} >Estado(isActive)</label>
                                                    <Field onChange={this.handleChange} name="isActive" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">

                                                    <option value="">{formatMessage(messages.allonly)}</option>
                                                        <option value="1">Activo</option>
                                                        <option value="0">Inactivos</option>

                                                    </Field>
                                                </FormGroup>
                                            </Col>


                                            <Col xs={12} sm={3} md={3} lg={3}>
                                                <FormGroup className={s.formGroup}>
                                                    <label className={bt.labelText} > Cuenta (activeStatus)</label>
                                                    <Field onChange={this.handleChange} name="acountStatus" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                                                        <option value="active">Activo</option>
                                                        <option value="pending">Pendiente</option>
                                                        <option value="inactive">Inactivo</option>
                                                        <option value="">{formatMessage(messages.allonly)}</option>

                                                    </Field>
                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} sm={3} md={3} lg={3}>
                                                <FormGroup className={s.formGroup}>
                                                    <label className={bt.labelText} >Estado Vehículo</label>
                                                    <Field onChange={this.handleChange} name="vehicleStatus" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                                                    <option value="">{formatMessage(messages.allonly)}</option>
                                                        <option value="pending">{formatMessage(messages.pending)}</option>
                                                        <option value="active">{formatMessage(messages.active)}</option>
                                                        <option value="inactive">{formatMessage(messages.inactive)}</option>

                                                    </Field>
                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} sm={3} md={3} lg={3}>
                                                <FormGroup className={s.formGroup}>
                                                    <div>
                                                        <label className={bt.labelText} >{formatMessage(messages.vehicleType)}</label>
                                                        <Field onChange={this.handleChange} name="vehicleType" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                                                        <option value="">{formatMessage(messages.allonly)}</option>

                                                            {
                                                                getActiveCategories && getActiveCategories.result && getActiveCategories.result.map(category => (
                                                                    <option key={category.id} value={category.id}>{category.categoryName}</option>
                                                                ))
                                                            }
                                                        </Field>
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} >
                                    <p>Lista de Usuarios</p>

                                        <div className={s.root}>
                                            <FormControl
                                                type="text"
                                                placeholder="Buscar..."
                                                className={bt.formControlInput}
                                                value={searchList}
                                                onChange={this.handleSearchChange}
                                                onPaste={this.handlePaste} />
                                            <div style={{ padding: '10px 0', display: 'flex', gap: '10px' }}>

                                                <button onClick={this.toggleSelectAll} className="btn btn-primary">
                                                    {this.areAllSelected() ? 'Desmarcar x Página' : 'Marcar x Página'}
                                                </button>

                                                <button onClick={this.toggleSelectAll2} className="btn btn-primary">
                                                    {this.areAllSelected2() ? 'Desmarcar Todos' : 'Marcar Todos'}
                                                </button>
                                            </div>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th><FormattedMessage {...messages.gender} /></th>
                                                        <th>Correo</th>

                                                        <th>Dispositivo</th>
                                                        <th>Tipo de Cuenta</th>

                                                        <th><FormattedMessage {...messages.status} /></th>
                                                        <th>Estado Cuenta</th>
                                                        <th>Estado Vehículo</th>
                                                        <th>Tipo de Vehículo</th>



                                                        <th>Seleccionar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllRiders && getAllRiders.userData.map((user, index) => (
                                                        <tr key={index}>
                                                            <td>{user.profile.firstName} {user.profile.lastName}</td>
                                                            <td>{statusTranslations[user.profile.gender]}</td>
                                                            <td>{user. email}</td>

                                                            <td>
                                                                {user.logins && user.logins.length > 0 ? statusTranslations[user.logins[0].deviceType] : 'Sin dispositivo'}
                                                            </td>
                                                            <td>
                                                                {user.userType === 2 ? 'Conductor' : 'Pasajero'}
                                                            </td>
                                                            <td> {user.isActive === 1 ? 'Activo' : 'Inactivo'}</td>

                                                            <td>{statusTranslations[user.userStatus] || user.userStatus}</td>
                                                            <td>
                                                                {user.vehicles && user.vehicles.length > 0 ? statusTranslations[user.vehicles[0].vehicleStatus] : 'Sin vehículo'}
                                                            </td>
                                                            <td>
                                                                {user.vehicles && user.vehicles.length > 0 ? vehicleTypeMap[user.vehicles[0].vehicleType] : 'Sin vehículo'}
                                                            </td>


                                                            <td>
                                                                <div
                                                                    onClick={() => this.handleCheckboxChange(user.id)}
                                                                    className={selectedUsers.includes(user.id) ? s.checkboxChecked : s.checkbox}
                                                                >
                                                                    {selectedUsers.includes(user.id) && <span>✓</span>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            {getAllRiders && (
                                                <CustomPagination
                                                    total={getAllRiders.count}
                                                    currentPage={currentPage}
                                                    change={this.paginationData} />
                                            )}
                                        </div>

                                    </Col>

                                    
                                </Row>



                            </Col>


                        </Row>




                        <form onSubmit={handleSubmit((values) => submit(values, this.props.dispatch, { selectedUsers: this.state.selectedUsers }))}>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                                    <Field name="message" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.msgOnly)} />
                                    <Picker data={data} onEmojiSelect={this.handleEmojiSelect} i18n={i18n} />
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                                {this.renderSelectedUsersList()}

                                </Col>



                            </Row>
                            <Row>
                                <Col xs={12} sm={8} md={8} lg={6} className={cx(bt.textAlignRight, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                                    <div>

                                        <div className={s.displayInlineBlock}>
                                            <Loader
                                                type={"button"}
                                                label={formatMessage(messages.submitButton)}
                                                show={loading}
                                                buttonType={'submit'}
                                                className={cx(bt.btnPrimary)}
                                                disabled={submitting || loading}
                                                isSuffix={true}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                </div>
            </div>
        )
    }
}



ManageNotificationsForm = reduxForm({
    form: 'ManageNotificationsForm',
    onSubmit: submit,
    validate
})(ManageNotificationsForm)
const selector = formValueSelector('ManageNotificationsForm'); // Nombre de tu formulario
const mapState = (state) => ({
    message: selector(state, 'message'), // Obtenemos el valor del campo 'message'
    loading: state.loader.sendNotification, // Asume que este es tu estado actual para 'loading'
})
const mapDispatch = {
    initialize
}
export default compose(
    injectIntl,
    withStyles(s, bt),
    connect(mapState, mapDispatch),
    graphql(getAllRiders, {
        name: 'riderDetails',
        options: (props) => ({
            variables: {
                currentPage: 1,
                searchList: '',
                acountStatus: '', // Asumiendo que quieres esta variable aquí
            },
            fetchPolicy: 'network-only',
        }),
    }),
    graphql(getActiveCategories, {
        name: 'getActiveCategories',
        options: ({
            fetchPolicy: 'network-only',
            ssr: true
        })
    }),
    graphql(getAllUsers, { name: 'allUsers' }) // Ejecuta la consulta GetAllUsers
)(ManageNotificationsForm);
