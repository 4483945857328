import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import { Table } from 'react-bootstrap';
import s from './vehicleDocument.css';

import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';

function action({ store, intl }) {
  const title = "Gestionar Documentos";  // Update title message key

  const datosEjemplo = [
    { id: '1', nombre: 'Ejemplo 1', categoria: 'Categoría A', configuracion: 'Configuración 1' },
    { id: '2', nombre: 'Ejemplo 2', categoria: 'Categoría B', configuracion: 'Configuración 2' },
    // Agrega más objetos según sea necesario
  ];
  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

  if (!isAdminAuthenticated) {
    return { redirect: '/login' };
  }



  return {
    title,
    component: (
      <AdminLayout>
       <div style={{ textAlign: 'center' }}>
        <h1>En Construcción</h1>
      </div>

     
        
      </AdminLayout>
    ),
  };
}

export default action;
