import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AddCargoTypeForm from '../../../components/SiteAdmin/AddCargoTypeForm/AddCargoTypeForm'

export class AddCargoType extends Component {

    render() {
        return (
            <div>
                <AddCargoTypeForm />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCargoType)
