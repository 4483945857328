import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomPagination from '../../CustomPagination';
import CurrencyConverter from '../../CurrencyConverter'
import Link from '../../Link/Link';
import Loader from '../../../components/Common/Loader';

import { updatePayoutStatus } from '../../../actions/siteadmin/Payout/updatePayout';
import { updateCashPayout } from '../../../actions/siteadmin/Payout/updateCashPayout'

import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirst';
import debounce from '../../../helpers/debounce';

import messages from '../../../locale/messages';

import s from './AutoPayout.css';
import bt from '../../../components/commonStyle.css';

import ExportImage from '../../../../public/Icons/export.png';
import ExportImageDark from '../../../../public/Icons/exportDark.png';

export class AutoPayoutList extends Component {
    static propTypes = {
        getPayoutData: PropTypes.object,
        updatePayoutStatus: PropTypes.any.isRequired,
    }
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            selectedPayout: [],
            successPayout: [],
            payoutFilterStatus: '',
        }
        this.paginationData = this.paginationData.bind(this);
        this.holdPayout = this.holdPayout.bind(this);
        this.cashPayout = this.cashPayout.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
    }
    handleKeywordSearch(searchList) {
        const { getPayoutData: { refetch } } = this.props
        let variables = {
            currentPage: 1,
            searchList: searchList
        }
        this.setState(variables)
        refetch(variables)
    }
    paginationData(currentPage) {
        const { getPayoutData: { refetch } } = this.props;
        if (!currentPage) currentPage = this.state.currentPage;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }
    cashPayout(id, value) {
        const { updateCashPayout } = this.props;
        const { searchList, payoutFilterStatus, currentPage } = this.state;
        let payoutStatus = value == 'true' ? true : false;
        this.changeLoaderState('addUpdateStatus', id);
        updateCashPayout(id, payoutStatus, this.paginationData, this.changeLoaderState, searchList, payoutFilterStatus, currentPage)
    }
    holdPayout(e, id, currentPage) {
        const { updatePayoutStatus, getPayoutData: { refetch } } = this.props;
        let isBanStatus = (e.target.value);
        if (isBanStatus == "true" || isBanStatus == "false") {
            isBanStatus = (isBanStatus == "true") ? true : false;
            updatePayoutStatus(id, isBanStatus);
        }
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }
    changeLoaderState = (type, value) => {
        const { selectedPayout, successPayout } = this.state;
        let variables = {};
        if (type === 'addUpdateStatus') {
            variables = { selectedPayout: [...selectedPayout, value] };
        }
        if (type === 'removeUpdateStatus') {
            let index = selectedPayout.findIndex(i => i === value);
            if (index === -1) return '';
            let data = [...selectedPayout];
            data.splice(index, 1)
            variables = { selectedPayout: data };
        }
        if (type === 'successUpdateStatus') {
            variables = { successPayout: [...successPayout, { id: value }] };
        }
        this.setState(variables)
    };

    payoutStatusFilter = (payoutFilterStatus) => {
        const { getPayoutData: { refetch } } = this.props;
        const { searchList } = this.state;
        this.setState({ currentPage: 1, payoutFilterStatus });
        refetch({ currentPage: 1, searchList, payoutFilterStatus });
    };

    renderPayout = (value) => {
        const { intl: { formatMessage } } = this.props;
        const { selectedPayout, successPayout } = this.state;

        if (!value || !value.id) return <span>-</span>;

        let isSuccess = successPayout.find(item => item.id === value.id);

        if ((isSuccess && isSuccess.id) || value.isPayoutPaid) return <span>{formatMessage(messages.completedLabel)}</span>;

        if (selectedPayout.includes(value.id)) return <span className={s.processingtext}>{formatMessage(messages.processingLabel)}</span>;

        return (
            <select value={value.isPayoutPaid} onChange={(e) => { this.cashPayout(value.id, e.target.value) }} className={bt.selectInput}>
                <option value={false}>{formatMessage(messages.pending)}</option>
                <option value={true}>{formatMessage(messages.completedLabel)}</option>
            </select>
        );
    };

    render() {
        const { getPayoutData, getPayoutData: { getPayoutList }, loading, selectedTheme } = this.props;
        const { currentPage, searchList, payoutFilterStatus } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div className={cx(s.widthInner, 'bgBlackTwo')}>
                <div className={"autoPayoutFlexContainer"}>
                    <div className="autoPayoutButtonFlexContainer">
                        <div className={cx(s.searchInput, 'searchInputRTL', "autoPayoutSearchInput", "autoPayoutSearchInputRtl")}>
                            <FormControl type='text' placeholder={formatMessage(messages.searchOnly)} onChange={(e) => this.handleKeywordSearch(e.target && e.target.value)} className={bt.formControlInput} />
                        </div>
                        <div className={cx(s.searchInput, 'searchInputRTL', "autoPayoutSearchInput", "autoPayoutSearchInputRtl")}>
                            <FormControl as={"select"} placeholder={formatMessage(messages.searchOnly)} onChange={(e) => this.payoutStatusFilter(e.target && e.target.value)} className={bt.formControlInput} >
                                <option value={""}>{formatMessage(messages.all)}</option>
                                <option value={"pending"}>{formatMessage(messages.pending)} </option>
                                <option value={"completed"}>{formatMessage(messages.completedLabel)} </option>
                            </FormControl>
                        </div>
                    </div>
                    <div className={cx(s.exportTextSection, 'textAlignLeftRTL', 'textAlignRightMbRTL')}>
                        {
                            getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length > 0 && <a
                                href={`/export-admin-data?type=payout&keyword=${searchList ? searchList : ''}&payoutstatus=${payoutFilterStatus ? payoutFilterStatus : ''}`}
                                className={cx('pull-right', s.textDecorationNone)}>
                                <span className={cx(s.vtrMiddle, s.exportText, 'exportTextRTL')}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                                {
                                    (selectedTheme && selectedTheme === "light") ?
                                        <img src={ExportImage} className={cx(s.exportImg)} /> : <img src={ExportImageDark} className={cx(s.exportImg)} />
                                }
                            </a>
                        }
                    </div>
                </div>
                <div class="clearfix"></div>
                {
                    loading && <div>
                        <Loader type="circle" />
                    </div>
                }
                {!loading &&
                    <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewResponsiveTable', 'topScrollbarTable', 'whiteDropdown')}>
                        <Table className={cx("table", 'topScrollbarTableContent')}>
                            <thead>
                                <tr>
                                    <th scope="col"><FormattedMessage {...messages.id} /></th>
                                    <th scope="col"><FormattedMessage {...messages.driverName} /></th>
                                    <th scope="col"><FormattedMessage {...messages.email} /></th>
                                    <th scope="col"><FormattedMessage {...messages.payoutAmount} /></th>
                                    <th scope="col"><FormattedMessage {...messages.payoutStatus} /></th>
                                    <th scope="col"><FormattedMessage {...messages.payoutStatusLabel} /></th>
                                    {/* <th scope="col"><FormattedMessage {...messages.holdPayout} /></th> */}
                                    <th scope="col"><FormattedMessage {...messages.details} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length > 0 && getPayoutData.getPayoutList.bookingData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td data-label={formatMessage(messages.id)}>{item.id}</td>
                                                <td data-label={formatMessage(messages.driverName)}>{item && item.driverDetails && capitalizeFirstLetter(item.driverDetails.firstName)}</td>
                                                <td data-label={formatMessage(messages.email)}>{item && item.driverDetails && item.driverDetails.userData && item.driverDetails.userData.email}</td>
                                                <td data-label={formatMessage(messages.payoutAmount)}><CurrencyConverter from={item.currency} amount={item.driverTotalFare} /></td>
                                                <td data-label={formatMessage(messages.payoutStatus)}>{(item.paymentType === 1) ? formatMessage(messages['processedCash']) : (item.isPayoutPaid && item.isPayoutPaid == true) ? formatMessage(messages['completed']) : formatMessage(messages['pending'])}</td>
                                                <td data-label={formatMessage(messages.payoutStatusLabel)}>
                                                    {this.renderPayout(item)}
                                                </td>
                                                {/* {item.isPayoutPaid == '1' ? <td data-label={formatMessage(messages.holdPayout)}>{formatMessage(messages.notRequiredMessage)}</td> :
                                                    <td data-label={formatMessage(messages.holdPayout)}>
                                                        <select value={item.isBanStatus} onChange={(e) => { this.holdPayout(e, item.id, currentPage) }} className={bt.selectInput}>
                                                            <option value={true}>{formatMessage(messages.yes)}</option>
                                                            <option value={false}>{formatMessage(messages.no)}</option>
                                                        </select>
                                                    </td>
                                                } */}
                                                <td data-label={formatMessage(messages.details)}><Link to={'/siteadmin/payout/view/' + item.id}><FormattedMessage {...messages.view} /></Link></td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length == 0 && (
                                        <tr>
                                            <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                }
                {
                    getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length > 0
                    && <div className={cx(bt.space5, bt.spaceTop5)}>
                        <CustomPagination
                            total={getPayoutData.getPayoutList.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.drivPayout)}
                        />
                    </div>
                }
            </div>
        )
    }
}
const mapDispatch = {
    updatePayoutStatus,
    updateCashPayout
};
const mapState = (state) => ({
    loading: state.intl.loading,
    selectedTheme: state.currency.theme
});
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AutoPayoutList)));

