import gql from 'graphql-tag';
import histroy from '../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';

export function editCargoType(id, name, description, isActive) {

  return async (dispatch, getState, { client }) => {
    const mutation = gql`
      mutation updateCargoType(
        $id: Int, 
        $name: String,
        $description: String,
        $isActive: Boolean,
   
      ) {
        updateCargoType(
          id: $id,
          name: $name,
          description: $description,
          isActive: $isActive,
         
        ){
          status
          errorMessage
        }
      }
    `;

    dispatch(setLoaderStart('EditCargoType'));

    const { data } = await client.mutate({
      mutation,
      variables: {
        id,
        name,
        description,
        isActive: String(isActive) === 'true' ? 1 : 0
      }
    });

    dispatch(setLoaderComplete('EditCargoType'));
    if (data && data.updateCargoType && data.updateCargoType.status === 200) {
      histroy.push('/siteadmin/cargoType');
      toastr.success('Success', 'CargoType has been updated!')
    } else if (data && data.updateCargoType && data.updateCargoType.status === 400) {
      toastr.error('Error!', data.updateCargoType.errorMessage)
    }
  }
}