import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import CargoType from './CargoType';  // Update import to use CargoType

function action({ store, intl }) {
  const title = "CargoType";  // Update title message key

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

  if (!isAdminAuthenticated) {
    return { redirect: '/login' };
  }



  return {
    title,
    component: (
      <AdminLayout>
        <CargoType/>  
      </AdminLayout>
    ),
  };
}

export default action;
