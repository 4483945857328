import { combineReducers } from 'redux';
import intl from './intl';
import loader from './loader';
import runtime from './runtime';
import siteSettings from './siteSettings';
import user from './user';

// External Reducers
import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as formReducer } from 'redux-form';

import adminPrevileges from './adminUserReducer';
import currency from './currency';
import mapData from './mapData';
import adminModalStatus from './modalReducer';
import page from './page';

export default combineReducers({
  user,
  runtime,
  intl,
  form: formReducer,
  toastr: toastrReducer,
  currency,
  siteSettings,
  loader,
  adminPrevileges,
  adminModalStatus,
  mapData,
  page
});
