import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';

// Translation
import { injectIntl, FormattedNumber } from 'react-intl';

// Helper
import { convert } from '../../helpers/currencyConvertion';
import { isRTL } from '../../helpers/formatLocale';

class CurrencyConverter extends Component {

  static propTypes = {
    from: PropTypes.string,
    amount: PropTypes.number,
    base: PropTypes.string,
    rates: PropTypes.object,
    superSymbol: PropTypes.bool,
    className: PropTypes.string,
    toCurrency: PropTypes.string,
  };

  static defaultProps = {
    amount: 0,
    superSymbol: false,
    base: null,
    rates: null
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { from, amount, superSymbol, className } = this.props;
    const { base, rates, toCurrency, locale } = this.props;

    let targetCurrency;
    let convertedAmount = amount;
    let fromCurrency = from || base;

    if (rates != null) {
      convertedAmount = convert(base, rates, amount, fromCurrency, toCurrency);
    }

    if (toCurrency) {
      targetCurrency = toCurrency;
    } else {
      targetCurrency = base;
    }

    if (targetCurrency === 'CLP') {
      convertedAmount = Math.round(Number(convertedAmount));
      if (Number(convertedAmount).toString().length === 4) {
        let amountInString = Number(convertedAmount).toString();
        return (
          <span className={className}>
            ${amountInString[0] + '.' + amountInString.substring(1, 4)}
          </span>
        );
      }
      let showAmount = new Intl.NumberFormat('es', {
        style: "currency",
        currency: targetCurrency,
        currencyDisplay: "symbol"
      }).format(convertedAmount || 0);
      if (showAmount) {
        showAmount = showAmount.replace('CLP', '');
        showAmount = showAmount.trim();
      }
      return (
        <span className={className}>
          ${showAmount || 0}
        </span>
      );
    }

    return (
      <span className={className}>
        {
          isRTL(locale) ?
            new Intl.NumberFormat('en-US', {
              style: "currency",
              currency: targetCurrency,
              minimumFractionDigits: convertedAmount % 1 === 0 ? 0 : 2,
              maximumFractionDigits: convertedAmount % 1 === 0 ? 0 : 2,
              currencyDisplay: "symbol"
            }).format(convertedAmount)
            : <FormattedNumber
              value={convertedAmount}
              style="currency"
              currency={targetCurrency}
              minimumFractionDigits={convertedAmount % 1 === 0 ? 0 : 2}
              maximumFractionDigits={convertedAmount % 1 === 0 ? 0 : 2}
              currencyDisplay={"symbol"}
            />
        }
        {
          superSymbol && <sup>{targetCurrency}</sup>
        }
      </span>
    )
  }
}

const mapState = (state) => ({
  base: state.currency.base,
  toCurrency: state.currency.to,
  rates: state.currency.rates,
  locale: state.intl.locale
});

const mapDispatch = {};

export default compose(
  injectIntl,
  connect(mapState, mapDispatch),
)(CurrencyConverter);

