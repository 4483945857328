import { addCargoType } from '../../../actions/siteadmin/addCargoType';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
   
    await dispatch(
        addCargoType(
            values.name,
            values.description,
            values.isActive,
          
        )
    )

}

export default submit;