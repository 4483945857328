import gql from 'graphql-tag';
import {
  CARGOTYPE_UPDATE_SUCCESS,
  CARGOTYPE_UPDATE_START,
  CARGOTYPE_UPDATE_ERROR
} from '../../constants/index';
import { toastr } from 'react-redux-toastr';

export default function updateCargoTypeStatus(id, isActive, currentPage) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: CARGOTYPE_UPDATE_START
    });

    try {
      let errorMessage;

      let query = gql`
        query getAllCargoType($currentPage: Int, $searchList: String){
          getAllCargoType(currentPage: $currentPage, searchList: $searchList){
            count
            cargoTypeData{
                id
                name
                description
                isActive
                createdAt
                updatedAt
            }
          }
        }
      `;

      let mutation = gql`
        mutation updateCargoTypeStatus($id: Int, $isActive: Boolean) {
          updateCargoTypeStatus(id: $id, isActive: $isActive) {
            status
            errorMessage
          }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          isActive: isActive == '1' ? true : false
        },
        refetchQueries: [{ query, variables: { currentPage, searchList: '' } }]
      });

      if (data && data.updateCargoTypeStatus && data.updateCargoTypeStatus.status === 200) {
        dispatch({
          type: CARGOTYPE_UPDATE_SUCCESS
        });
        toastr.success('Success', `The CargoType status has been updated.`);
      } else {
        dispatch({
          type: CARGOTYPE_UPDATE_ERROR
        });

        errorMessage = (data && data.updateCargoTypeStatus && data.updateCargoTypeStatus.errorMessage) || "Oops! Something went wrong. Please try again.";
        toastr.error('Error!', errorMessage);
      }
    } catch (err) {
      toastr.error('Error!', err);
      dispatch({
        type: CARGOTYPE_UPDATE_ERROR
      });
    }
  };
}
