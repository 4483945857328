import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import EditCargoType from './EditCargoType';
import messages from '../../../locale/messages';
import { restrictUrls } from '../../../helpers/adminPrivileges';

function action({ store, params, intl }) {
    //   const title = "Edit Category"
    const title = "Edit Cargo Type";

    // From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/login' }
    }

    const id = Number(params.id);

  

    return {
        title,
        component: (
            <AdminLayout>
                <EditCargoType title={title} id={id} />
            </AdminLayout>
        )
    }
}

export default action;