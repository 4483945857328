import { editCargoType } from '../../../actions/siteadmin/editCargoType';

async function submit(values, dispatch) {

    await dispatch(
        editCargoType(
            values.id,
            values.name,
            values.description,
            values.isActive,
        
        )
    )
}

export default submit;