import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout'
import AddCargoType from './AddCargoType'
import messages from '../../../locale/messages';
import { restrictUrls } from '../../../helpers/adminPrivileges';

function action({ store, intl }) {
    // const title =  'Add Category'
    const title = "Agregar tipo de carga";

    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/login' }
    }


    return {
        title,
        component: (
            <AdminLayout>
                <AddCargoType title={title} />
            </AdminLayout>
        )
    }
}

export default action;