import React from 'react';
import { connect } from 'react-redux';  // Asegúrate de importar connect
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table,Button, FormControl, ButtonToolbar } from 'react-bootstrap';
import s from './CargoTypeList.css';  // Asegúrate de que el nombre del archivo CSS sea correcto
import bt from '../../../components/commonStyle.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';
// local
import messages from '../../../locale/messages';
import CustomPagination from '../../CustomPagination';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getAllCargoType from './getAllCargoType.graphql';  // Asegúrate de importar el archivo GraphQL correcto
import PropTypes from 'prop-types';
import Link from '../../Link';
import history from '../../../history';
import Loader from '../../Common/Loader/Loader';  // Asegúrate de importar el componente Loader correcto
import TrashIcon from '../../../../public/Icons/bin.svg';
import EditIcon from '../../../../public/Icons/edit.png';
import updateCargoTypeStatus from '../../../actions/siteadmin/updateCargoTypeStatus';
import deleteCargoType from '../../../actions/siteadmin/deleteCargoType';

class CargoTypeList extends React.Component {
  static propTypes = {
    cargoTypeDetails: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
    };
    this.paginationData = this.paginationData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
  }

  handleSearchClick(searchList) {
    const { cargoTypeDetails: { refetch } } = this.props;
    let variables = {
      currentPage: 1,
      searchList: searchList,
    };
    this.setState({ currentPage: 1 });
    refetch(variables);
  }

  handleSearchChange(e) {
    let self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList);
      }, 450),
    });
  }

  paginationData(currentPage) {
    const { cargoTypeDetails: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  async handleStatus(e, id, currentPage) {
    const { updateCargoTypeStatus, cargoTypeDetails: { refetch } } = this.props;
    let status = e.target.value == 'true' ? '1' : '0';

    await updateCargoTypeStatus(id, status, currentPage);
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);

  }

  async handleDelete(id, currentPage) {
    const { deleteCargoType, cargoTypeDetails: { refetch } } = this.props;
    let variables = { currentPage }
    await deleteCargoType(id, currentPage);
    refetch(variables)
  }

  render() {
    const { cargoTypeDetails, cargoTypeDetails: { getAllCargoType }, loading } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage } = this.state;

    return (
      <div className={cx(s.widthInner, 'whiteDropdown', 'bgBlackTwo')}>
        <div className={cx(s.searchInput, 'searchInputRTL')}>
          <FormControl type='text' placeholder={formatMessage(messages.searchOnly)} onChange={(e) => this.handleSearchChange(e)} className={bt.formControlInput} />
        </div>

        <div className={cx(bt.padding2, bt.paddingTop2, s.displayInlineBlock, s.rightSide, 'floatLeftRTL', 'floatMbRightRTL')}>
          <Link to={"/siteadmin/cargoType/add"} className={cx(bt.btnPrimary, 'btnPrimaryDark')} >Agregar tipo de carga</Link>
        </div>

        <div className="clearfix"></div>

        {loading && <div>
          <Loader type="circle" />
        </div>}

        {!loading &&
          <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewResponsiveTable', 'topScrollbarTable')}>
            <Table className={cx("table", 'topScrollbarTableContent')}>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Fecha</th>
                  <th scope="col"><FormattedMessage {...messages.action} /></th>
                  <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>

                  {/* Remove the columns related to actions */}
                </tr>
              </thead>
              <tbody>
                {
                  cargoTypeDetails && cargoTypeDetails.getAllCargoType && cargoTypeDetails.getAllCargoType.cargoTypeData && cargoTypeDetails.getAllCargoType.cargoTypeData.length > 0 && cargoTypeDetails.getAllCargoType.cargoTypeData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td >{item.id}</td>
                        <td >{item.name}</td>
                        <td >{item.description}</td>
                        <td data-label={formatMessage(messages.status)}>
                          <select value={item.isActive ? 'true' : 'false'} onChange={(e) => { this.handleStatus(e, item.id, currentPage) }} className={bt.selectInput}>
                            <option value="true">{formatMessage(messages.active)}</option>
                            <option value="false">{formatMessage(messages.inactive)}</option>
                          </select>
                        </td>
                        <td data-label={formatMessage(messages.createdAt)}>{moment(moment(item.createdAt)).format('DD-MM-YYYY HH:mm:ss')}</td>
                        <td data-label={formatMessage(messages.action)}>
                          <Link to={'/siteadmin/cargoType/edit/' + item.id} className={cx('editAlign')}>
                            <span className={'svgImg'}><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                            <span className={s.vtrMiddle}>
                              <FormattedMessage {...messages.editAction} />
                            </span>
                          </Link>
                        </td>
                        <td data-label={formatMessage(messages.deleteAction)}>
                          <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'svgImg', 'btnPrimaryDeleteDark')}>
                            <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                            <span className={s.vtrMiddle}>
                              <FormattedMessage {...messages.deleteAction} />
                            </span>
                          </Button>

                        </td>
                      </tr>
                    );
                  })
                }
                {
                  cargoTypeDetails && cargoTypeDetails.getAllCargoType && cargoTypeDetails.getAllCargoType.cargoTypeData && cargoTypeDetails.getAllCargoType.cargoTypeData.length === 0 && (
                    <tr>
                      <td colSpan="7" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          </div>}
        {
          cargoTypeDetails && cargoTypeDetails.getAllCargoType && cargoTypeDetails.getAllCargoType.cargoTypeData && cargoTypeDetails.getAllCargoType.cargoTypeData.length > 0
          && <div className={cx(bt.space5, bt.spaceTop5)}>
            <CustomPagination
              total={cargoTypeDetails.getAllCargoType.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel="Tipos de carga"
            />
          </div>
        }
      </div>
    );
  }
}

const mapDispatch = {
  updateCargoTypeStatus,
  deleteCargoType
};
const mapState = (state) => ({
  loading: state.intl.loading,
});

export default injectIntl(compose(
  withStyles(s, bt),
  graphql(getAllCargoType, {
    name: 'cargoTypeDetails',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      fetchPolicy: 'network-only',
    },
  }),
  connect(mapState, mapDispatch)
)(CargoTypeList));
