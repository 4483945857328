import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ManageNotifications.css';
import Loader from '../../../components/Common/Loader/Loader';
import ManageNotificationsForm from '../../../components/SiteAdmin/ManageNotificationsForm'

import getSiteSettings from './getSiteSettings.graphql'

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
export class ManageNotifications extends Component {

  render() {
    
    const { formatMessage } = this.props.intl;
    const { getSiteSettings: { loading, getSiteSettings } } = this.props
    return (
      <div>
        <Loader type={"page"} show={loading}>
          <div className={s.root}>
            <div className={s.container}>
              <div className={s.heading}>
              Gestionar Notificaciones
              </div>
              <div className={s.paddingRoutesSection}>
                {!loading && <ManageNotificationsForm getSiteSettings={getSiteSettings}  />}
              </div>
            </div>
          </div>
        </Loader>
      </div>
    )
  }
}


export default compose(
  injectIntl,
  withStyles(s),
  graphql(getSiteSettings, {
    name: 'getSiteSettings',
    options: {
      variables: {
        type: 'site_settings',
      },
      fetchPolicy: 'network-only',
    }
  }),



)(ManageNotifications)
