import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import s from './CargoType.css';
import CategoryList from '../../../components/CargoType/CargoTypeList/CargoTypeList';
import getAllCargoTypeQuery from './getAllCargoType.graphql';
import Loader from '../../../components/Common/Loader/Loader';
import { injectIntl } from 'react-intl';

class CargoType extends React.Component {
  static propTypes = {
    cargoTypeDetails: PropTypes.shape({
      
      getAllCargoType: PropTypes.array,
    }),
  };

  static defaultProps = {
    cargoTypeDetails: {
      loading: true,
    },
  };

  render() {
    const { cargoTypeDetails, cargoTypeDetails: { loading, getAllCargoType } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
              Gestionar tipos de carga
            </div>
            <div className={s.paddingRoutesSection}>
              <CategoryList categoryDetails={cargoTypeDetails} />
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(getAllCargoTypeQuery, {
    name: 'cargoTypeDetails',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
    },
  })
)(CargoType);
