import React, { Component } from 'react';
import EditCargoTypeForm from '../../../components/SiteAdmin/EditCargoTypeForm/EditCargoTypeForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getCargoType from './getCargoType.graphql';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditCargoType.css';
import Loader from '../../../components/Common/Loader/Loader';
import NotFound from '../../../components/NotFound/NotFound';

export class EditCargoType extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }
    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { data: { loading, getCargoType }, data, id } = this.props;

        let initialValues = {};

        if (!loading && getCargoType) {
            initialValues = {
                id: getCargoType.id,
                name: getCargoType.name,
                description: getCargoType.description,
                isActive: getCargoType.isActive
            }
        }
        if (loading) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        }
        else if (!getCargoType) {
            return <NotFound title={'Page Not Found'} />;
        }
        else {
            return (
                <div className={s.paddingRoutesSection}>
                    <EditCargoTypeForm initialValues={initialValues} />
                </div>
            )
        }
    }
}

export default compose(withStyles(s), graphql(getCargoType, {
    options: (props) => ({
        variables: {
            id: props.id
        },
        fetchPolicy: 'network-only',
        ssr: false
    })
}))(EditCargoType)
