import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import { Table } from 'react-bootstrap';
import s from './driversCategory.css';

import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import bt from '../../../components/commonStyle.css'
import Link from '../../../components/Link'


function action({ store, intl }) {
  const title = "Categorias del Musher";  // Update title message key

  const datosEjemplo = [
    { id: '1', nombre: 'Ejemplo 1', categoria: 'Categoría A', configuracion: 'Configuración 1' },
    { id: '2', nombre: 'Ejemplo 2', categoria: 'Categoría B', configuracion: 'Configuración 2' },
    // Agrega más objetos según sea necesario
  ];
  
  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

  if (!isAdminAuthenticated) {
    return { redirect: '/login' };
  }



  return {
    title,
    component: (
      <AdminLayout>
       <div style={{ textAlign: 'center' }}>
        <h1>{title}</h1>
        <div className={cx(bt.padding2, bt.paddingTop2, s.displayInlineBlock, s.rightSide, 'floatLeftRTL', 'floatMbRightRTL')}>
          <Link to={"/siteadmin/category/add"} className={cx(bt.btnPrimary, 'btnPrimaryDark')} >Nueva Categoria</Link>
          </div> 
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px',  margin: '20px 0', borderRadius: '8px' }}>
       
       <div className={cx(s.tableCssTwo, 'tableCssTwo', 'tableSticky', 'NewResponsiveTable', 'topScrollbarTable')}>
        <Table className={cx("table", 'topScrollbarTableContent')}>
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="table.column.id" defaultMessage="ID" /></th>
              <th scope="col"><FormattedMessage id="table.column.name" defaultMessage="Nombre" /></th>
              <th scope="col"><FormattedMessage id="table.column.category" defaultMessage="Categoría" /></th>
              <th scope="col"><FormattedMessage id="table.column.configuration" defaultMessage="Configuración" /></th>
            </tr>
          </thead>
          <tbody>
            {/* Ejemplo de filas insertadas dinámicamente */}
            {datosEjemplo.map((item, index) => (
              <tr key={index}>
                <td data-label="ID">{item.id}</td>
                <td data-label="Nombre">{item.nombre}</td>
                <td data-label="Categoría">{item.categoria}</td>
                <td data-label="Configuración">
                  <button className={cx('btn', 'btn-primary')} style={{ marginRight: '10px' }}>Editar</button>
                  <button className={cx('btn', 'btn-secondary')}>Eliminar</button>
                </td>
              </tr>
            ))}
            {/* Considera manejar el caso de no resultados con un mensaje adecuado */}
            {datosEjemplo.length === 0 && (
              <tr>
                <td colSpan="4" className={s.noRecords}><FormattedMessage id="table.noResults" defaultMessage="No hay resultados" /></td>
              </tr>
            )}
          </tbody>
        </Table>
        </div>
        </div>
      </div>

      </AdminLayout>
    ),
  };
}

export default action;
